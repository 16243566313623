import axios from 'axios'

const state = {
    layout: {
        patient: '',
        birth_date: '',
        complaint: '',
        anamnesis: '',
        objective: '',
        survey_data: '',
        diagnosis: '',
        is_creating: false
    },
    layouts: {},
    recipe: {},
    layouts_loading: false,
    layout_drugs: [],
    save_to_layout: false,
    layouts_errors: {},
    layouts_error_message: '',
    deleting_layout_dialog: false,
    recipe_number: '',
    recipe_html: '',
    sms_text: '',
}

const getters = {
    layout: state => state.layout,
    layouts: state => state.layouts,
    layouts_loading: state => state.layouts_loading,
    layout_drugs: state => state.layout_drugs,
    save_to_layout: state => state.save_to_layout,
    layouts_errors: state => state.layouts_errors,
    layouts_error_message: state => state.layouts_error_message,
    deleting_layout_dialog: state => state.deleting_layout_dialog,
    recipe_number: state => state.recipe_number,
    recipe_html: state => state.recipe_html,
    recipe: state => state.recipe,
    sms_text: state => state.sms_text,
}

const mutations = {
    setLayout(state, layout) {
        state.layout = layout
    },
    setLayouts(state, layouts) {
        state.layouts = layouts
    },
    setLayoutsLoading(state, loading) {
        state.layouts_loading = loading
    },
    setLayoutDrugs(state, drugs) {
        state.layout_drugs = drugs
    },
    setSaveToLayout(state, save) {
        state.save_to_layout = save
    },
    setLayoutsErrors(state, errors) {
        state.layouts_errors = errors
    },
    setLayoutsErrorMessage(state, message) {
        state.layouts_error_message = message
    },
    setDeletingLayoutDialog(state, dialog) {
        state.deleting_layout_dialog = dialog
    },
    setRecipeNumber(state, number) {
        state.recipe_number = number
    },
    setRecipeHtml(state, html) {
        state.recipe_html = html
    },
    setRecipe(state, recipe) {
        state.recipe = recipe
    },
    setSmsText(state, text) {
        state.sms_text = text
    }
}

const actions = {
    getLayouts({commit}, params) {
        commit('setLayoutsLoading', true)
        axios.get('/api/doctors/layouts', {params}).then(response => {
            commit('setLayouts', response.data)
            commit('setLayoutsLoading', false)
        })
    },
    async getLayout({commit}, id) {

        commit('setLayoutsLoading', true)
        commit('setLayout', {})
        try {
            var response = await axios.get('/api/doctors/layouts/' + id + '/show')
            commit('setLayout', response.data.data)
            commit('setLayoutDrugs', response.data.data.drugs)
            commit('setLayoutsLoading', false)
            return true
        } catch (e) {
            commit('setLayoutsErrors', e.response.data.errors)
            commit('setLayoutsErrorMessage', e.response.data.message)
            return false
        }
    },

    async storeLayout({commit}, layout) {
        commit('setLayoutsLoading', true)
        try {

            var response = await axios.post('/api/doctors/layouts/store', layout)
            if (!response.data.ok)
                commit('setLayoutsErrors', response.data.errors)
            layout.is_creating = false
            commit('setLayout', layout)
            return response.data.ok
        } catch (e) {
            commit('setLayoutsErrors', e.response.data.errors)
            commit('setLayoutsErrorMessage', e.response.data.message)
            return false
        }
    },

    async storeLayoutWithoutInfo({commit}, layout) {
        commit('setLayoutsLoading', true)
        try {

            var response = await axios.post('/api/doctors/layouts/store-without-info', layout)
            if (!response.data.ok)
                commit('setLayoutsErrors', response.data.errors)

            return response.data.ok
        } catch (e) {
            commit('setLayoutsErrors', e.response.data.errors)
            commit('setLayoutsErrorMessage', e.response.data.message)
            return false
        }
    },

    async updateLayout({commit}, layout) {
        commit('setLayoutsLoading', true)
        try {

            var response = await axios.post('/api/doctors/layouts/' + layout.id + '/update', layout)
            if (!response.data.ok)
                commit('setLayoutsErrors', response.data.errors)

            return response.data.ok
        } catch (e) {
            commit('setLayoutsErrors', e.response.data.errors)
            commit('setLayoutsErrorMessage', e.response.data.message)
            return false
        }
    },
    destroyLayout({commit, dispatch}, id) {
        commit('setLayoutsLoading', true)
        axios.delete('/api/doctors/layouts/' + id + '/destroy').then(() => {
            commit('setLayoutsLoading', false)
            dispatch('getLayouts')
        })
    },
    async storeRecipe({commit}, layout) {
        commit('setLayoutsLoading', true)
        try {

            var response = await axios.post('/api/doctors/recipes/store', layout)
            if (!response.data.ok) {
                commit('setLayoutsErrors', response.data.errors)
                commit('setLayoutsErrorMessage', response.data.message)
            } else {
                commit('setRecipeNumber', response.data.recipe_number)
            }

            return response.data.ok
        } catch (e) {
            commit('setLayoutsErrors', e.response.data.errors)
            commit('setLayoutsErrorMessage', e.response.data.message)
            return false
        }
    },
    async printRecipe({commit}, id) {
        commit('setLayoutsLoading', true)
        try {

            var response = await axios.get('/api/doctors/recipes/' + id + '/print',)
            if (!response.data.ok)
                commit('setLayoutsErrors', response.data.errors)
            else {
                commit('setRecipeHtml', response.data.view)
            }

            return response.data.ok
        } catch (e) {
            commit('setLayoutsErrors', e.response.data.errors)
            commit('setLayoutsErrorMessage', e.response.data.message)
            return false
        }
    },
    async sendRecipe({commit}, id) {
        commit('setLayoutsLoading', true)
        try {

            var response = await axios.post('/api/doctors/recipes/' + id + '/send',)
            if (!response.data.ok) {
                commit('setLayoutsErrors', response.data.errors)
            } else {
                commit('setSmsText', response.data.sms)
                commit('setRecipeHtml', response.data.view)
            }

            return response.data.ok
        } catch (e) {
            commit('setLayoutsErrors', e.response.data.errors)
            commit('setLayoutsErrorMessage', e.response.data.message)
            return false
        }
    },
    async getRecipe({commit}, id) {
        commit('setLayoutsLoading', true)
        try {

            var response = await axios.get('/api/doctors/recipes/' + id + '/show',)
            commit('setRecipe', response.data.data)
            commit('setLayout', response.data.data)
            return response.data.ok
        } catch (e) {
            commit('setLayoutsErrors', e.response.data.errors)
            commit('setLayoutsErrorMessage', e.response.data.message)
            return false
        }
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}