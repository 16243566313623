import axios from 'axios'

const state = {
    seller: {},
    seller_loading: false,
    seller_errors: {},
    token: '',
    refresh_token: '',
    stats_doctors_filter: {
        doctor_types: [],
        doctor_id: null,
        region: null,
        district: null,
        drugs: [],
    },
    orders: [],
    orders_loading: false,
    permissions: [],
}

const getters = {
    seller: state => state.seller,
    permissions: state => state.permissions,
    seller_loading: state => state.seller_loading,
    seller_errors: state => state.seller_errors,
    token: state => state.token,
    refresh_token: state => state.refresh_token,
    stats_doctors_filter: state => state.stats_doctors_filter,
    orders: state => state.orders,
    orders_loading: state => state.orders_loading,
}

const mutations = {
    setSeller(state, seller) {
        state.seller = seller
    },
    setPermissions(state, permissions) {
        state.permissions = permissions
    },
    setSellerLoading(state, loading) {
        state.seller_loading = loading
    },
    setSellerErrors(state, errors) {
        state.seller_errors = errors
    },
    setToken(state, token) {
        state.token = token
    },
    setRefreshToken(state, token) {
        state.refresh_token = token
    },
    setStatsDoctorFilter(state, stats_doctors_filter) {
        state.stats_doctors_filter = stats_doctors_filter
    },
    setOrders(state, orders) {
        state.orders = orders
    },
    setOrdersLoading(state, orders_loading) {
        state.orders_loading = orders_loading
    },
}

const actions = {
    clearStore({commit}) {
        commit('setSeller', {})
        commit('setToken', '')
    },
    async checkLogin({commit}, data) {
        commit('setSellerErrors', {})
        try {
            let response = await axios.post('/api/clinic/check-login', data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
    async getCode({commit}, data) {
        commit('setSellerErrors', {})
        try {
            let response = await axios.post('/api/clinic/register-send-code', data)
            console.log(response)
            return true
        } catch (error) {
            console.log(error);
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
    async sendCode({commit}, data) {
        commit('setSellerErrors', {})
        try {
            let response = await axios.post('/api/clinic/code', data)
            console.log(response)
            return true
        } catch (error) {
            console.log(error);
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
    async register({commit}, data) {
        commit('setSellerLoading', true)
        commit('setSellerErrors', {})
        try {
            let response = await axios.post('/api/clinic/register', data)
            commit('setSellerLoading', false)
            return response.data
        } catch (error) {
            console.log(error);
            commit('setSellerLoading', false)
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
    async logout({commit}) {
        commit('setSellerLoading', true)
        commit('setSellerErrors', {})
        try {
            let response = await axios.post('/api/logout', {})
            commit('setSellerLoading', false)
            commit('setToken', '')
            commit('setSeller', {})
            window.localStorage.clear()

            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setSellerLoading', false)
            commit('setSellerErrors', error.response.data.errors)
            commit('setToken', '')
            commit('setSeller', {})
            return false
        }
    },
    async impersonate({commit}) {
        commit('setSellerLoading', true)
        commit('setSellerErrors', {})
        try {
            let response = await axios.get('/api/clinic/me')
            commit('setSellerLoading', false)
            if (response.data.ok) {
                commit('setSeller', response.data.data)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                return true
            } else {
                return false
            }
        } catch (error) {
            commit('setSellerLoading', false)
            if (error.response.data && error.response.data.errors)
                commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
    async check_me({commit}) {
        commit('setSellerLoading', true)
        commit('setSellerErrors', {})
        try {
            let response = await axios.get('/api/clinic/me')
            commit('setSellerLoading', false)
            if (response.data.ok) {
                commit('setSeller', response.data.data)
                return {
                    ok: true, status: response.status
                }
            } else {
                return {ok: false, status: response.status}
            }
        } catch (error) {
            commit('setSellerLoading', false)
            if (error.response.data && error.response.data.errors)
                commit('setSellerErrors', error.response.data.errors)
            return {ok: false, status: error.response.status}
        }
    },
    getDoctorOrders({commit}, {id, params}) {
        commit('setOrders', {})
        commit('setOrdersLoading', true)
        axios.get(`/api/clinic/doctors/${id}/orders`, {params}).then(response => {
            commit('setOrders', response.data)
            commit('setOrdersLoading', false)
        })
    },
    getDoctorServices({commit}, {id, params}) {
        commit('setServices', {})
        commit('setServicesLoading', true)
        axios.get(`/api/clinic/doctors/${id}/services`, {params}).then(response => {
            commit('setServices', response.data)
            commit('setServicesLoading', false)
        })
    },
    async login({commit}, data) {
        commit('setSellerLoading', true)
        commit('setSellerErrors', {})
        try {
            let response = await axios.post('/api/clinic/login', data)
            commit('setSellerLoading', false)
            if (response.data.ok) {
                commit('setSeller', response.data.user)
                commit('setPermissions', response.data.user.permissions)
                commit('setToken', response.data.token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setSellerLoading', false)
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
    async forgotPassword({commit}, data) {
        commit('setSellerLoading', true)
        commit('setSellerErrors', {})
        try {
            let response = await axios.post('/api/clinic/forgot-password', data)
            commit('setSellerLoading', false)
            if (response.data.ok) {
                let seller = state.seller;
                seller.phone_number = data.phone_number
                commit('setSeller', seller)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setSellerLoading', false)
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
    async checkCode({commit}, data) {
        commit('setSellerLoading', true)
        commit('setSellerErrors', {})
        try {
            let response = await axios.post('/api/clinic/code', data)
            commit('setSellerLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setSellerLoading', false)
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },

    async resetPassword({commit}, data) {
        commit('setSellerLoading', true)
        commit('setSellerErrors', {})
        try {
            let response = await axios.post('/api/clinic/reset-password', data)
            commit('setSellerLoading', false)
            if (response.data.ok) {
                commit('setSeller', response.data.user)
                commit('setToken', response.data.token)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setSellerLoading', false)
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
    async requestAccess({commit}, data) {
        commit('setSellerLoading', true)
        commit('setSellerErrors', {})
        try {
            let response = await axios.post('/api/clinic/request-access', data)
            commit('setSellerLoading', false)
            if (!response.data.ok)
                commit('setSellerErrors', response.data.errors)
            return !!response.data.ok;
        } catch (error) {
            console.log(error);
            commit('setSellerLoading', false)
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
    async checkRequestStatus({commit}) {
        commit('setSellerLoading', true)
        commit('setSellerErrors', {})
        try {
            let response = await axios.get('/api/clinic/check-request-status')
            commit('setSellerLoading', false)
            if (response.data.status) {
                return response.data.status
            }
        } catch (error) {
            console.log(error);
            commit('setSellerLoading', false)
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
    async uploadFile({commit}, {data, type}) {
        commit('setSellerLoading', true)
        commit("setSellerErrors", [])
        try {
            let response = await axios.post('/api/clinic/upload-file', data)
            if (response.data.ok) {
                return response.data
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            let errors = {}
            errors[type] = error.response.data.errors.file
            commit("setSellerErrors", errors)
            return false
        }
    },
    async getMe({commit, getters}) {
        commit('setSellerLoading', true)
        try {
            let response = await axios.get('/api/clinic/me')
            if (response.data.ok) {
                commit('setSellerLoading', false)
                let clinic = getters.seller
                clinic.clinic = response.data?.data
                commit('setSeller', clinic)
                return response.data.data
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getStatDoctors({commit}, params) {
        try {
            let response = await axios.get('/api/clinic/statistics/doctors/table', {params})
            if (response.data.ok) {
                commit('setDoctors', response.data)
            }
            return true
        } catch (error) {
            console.log(error);
            commit('setSellerErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}