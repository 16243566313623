import axios from 'axios'

const state = {
    services: [],
    services_errors: [],
    services_loading: false,
    total_services: 0,
}

const getters = {
    services: state => state.services,
    services_loading: state => state.services_loading,
    services_errors: state => state.services_errors,
}

const mutations = {
    setServices(state, services) {
        state.services = services
    },
    setServicesLoading(state, services_loading) {
        state.services_loading = services_loading
    },
    setServicesErrors(state, services_errors) {
        state.services_errors = services_errors
    },
}

const actions = {
    getServices({commit}, params) {
        commit('setServicesLoading', true)
        axios.get('/api/services', {params})
            .then(response => {
                commit('setServices', response.data)
                commit('setServicesLoading', false)
            })
            .catch(error => {
                console.log(error)
                commit('setServicesLoading', false)
            })
    },
    async storeService({commit, dispatch}, service) {
        commit('setServicesErrors', [])
        commit('setServicesLoading', true)
        try {
            let response = await axios.post('/api/clinic/services/store', service)
            commit('setServices', response.data)
            dispatch('getServices')
            commit('setServicesLoading', false)
            return response.data
        } catch (e) {
            commit('setServicesErrors', e.response.data.errors)
            commit('setServicesLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}