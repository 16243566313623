const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/Index/IndexPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'base',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/doctors',
        name: 'doctors',
        component: () => import('@/pages/Doctor/DoctorsPage.vue'),
        meta: {
            navigation: "doctors" ,
            requiresAuth: true,
            layout: 'content',
            title: 'Barcha shifokorlar',
        }
    },
    {
        path: '/services',
        name: 'services',
        component: () => import('@/pages/Service/IndexPage.vue'),
        meta: {
            navigation: "doctors" ,
            requiresAuth: true,
            layout: 'content',
            title: 'Barcha muolajalar',
        }
    },
    {
        path: '/services/create',
        name: 'services.create',
        component: () => import('@/pages/Service/CreatePage.vue'),
        meta: {
            navigation: "doctors" ,
            requiresAuth: true,
            layout: 'content',
            title: "Muolaja qo'shish",
        }
    },
    {
        path: '/services/:id/edit',
        name: 'services.edit',
        component: () => import('@/pages/Service/EditPage.vue'),
        meta: {
            navigation: "doctors" ,
            requiresAuth: true,
            layout: 'content',
            title: "Muolaja qo'shish",
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/pages/User/IndexPage.vue'),
        meta: {
            navigation: "users" ,
            requiresAuth: true,
            layout: 'content',
            title: 'Barcha shifokorlar',
        }
    },
    {
        path: '/users/:id',
        name: 'users.show',
        component: () => import('@/pages/User/ShowPage.vue'),
        meta: {
            navigation: "users" ,
            requiresAuth: true,
            layout: 'content',
            title: 'Barcha shifokorlar',
        }
    },
    {
        path: '/doctors/:id',
        name: 'doctors.show',
        component: () => import('@/pages/Doctor/ShowPage.vue'),
        meta: {
            navigation: "doctors" ,
            requiresAuth: true,
            layout: 'content',
            title: 'Barcha shifokorlar',
        }
    },
    {
        path: "*",
        component: () => import('@/pages/Page/PageNotFound.vue'),
    },

    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/Clinic/RegisterPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Ro\'yxatdan o\'tish',
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Clinic/LoginPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Kirish',
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot_password',
        component: () => import('@/pages/Clinic/ForgotPassword.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Parolni tiklash',
        },
    },
    {
        path: '/password-code',
        name: 'password_code',
        component: () => import('@/pages/Clinic/PasswordCode.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Parolni tiklash',
        }
    },
    {
        path: '/new-password',
        name: 'new_password',
        component: () => import('@/pages/Clinic/NewPassword.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Parolni tiklash',
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/pages/Clinic/ProfilePage.vue'),
        meta: {
            navigation: "profile",
            requiresAuth: true,
            layout: 'content',
            title: 'Profil',
        }
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import('@/pages/Statistics/IndexPage.vue'),
        meta: {
            navigation: "statistics",
            requiresAuth: true,
            layout: 'content',
            title: 'Hisobotlar',
        }
    },
    // {
    //     path: '/statistics/doctors/:id',
    //     name: 'statistics.doctors.show',
    //     component: () => import('@/pages/Stats/DoctorStat.vue'),
    //     meta: {
    //         navigation: "doctors",
    //         requiresAuth: true,
    //         layout: 'content',
    //         title: 'Hisobotlar | Shifokor ',
    //     }
    // },
    {
        path: '/not-auth',
        name: 'not_auth',
        meta: {
            layout: 'content',
            requiresAuth: false,
            title: '401',
        },
        component: () => import('@/pages/Page/PageNotAllowed.vue'),
    },
    {
        path: '/impersonate',
        name: 'impersonate',
        component: () => import('@/pages/Index/ImpersonatePage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/privacy-policy',
        name: 'privacy_policy',
        component: () => import('@/pages/Page/PrivacyPolicyPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            title: 'Maxfiylik siyosati',
        }
    },


]

export default routes