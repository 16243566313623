import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
    routes, // short for `routes: routes`
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active" // active class for *exact* links.

})

router.beforeEach((to, from, next) => {
    // console.log(to)
    // console.log(from)
    // console.log(next)
    // console.log('router.beforeEach')
    let token = store.getters.token
    let user = store.getters.seller
    if(to.meta.requiresAuth) {
        if(token && user) {
            next()
        } else {
            next({name: 'login'})
        }
    } else {
        next()
    }
})


export default router
