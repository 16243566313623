import axios from 'axios'

const state = {
    clinic_services: [],
    clinic_service: [],
    clinic_services_errors: [],
    clinic_services_loading: false,
    total_clinic_services: 0,
}

const getters = {
    clinic_services: state => state.clinic_services,
    clinic_service: state => state.clinic_service,
    clinic_services_loading: state => state.clinic_services_loading,
    clinic_services_errors: state => state.clinic_services_errors,
}

const mutations = {
    setClinicServices(state, clinic_services) {
        state.clinic_services = clinic_services
    },
    setClinicService(state, clinic_service) {
        state.clinic_service = clinic_service
    },
    setClinicServicesLoading(state, clinic_services_loading) {
        state.clinic_services_loading = clinic_services_loading
    },
    setClinicServicesErrors(state, clinic_services_errors) {
        state.clinic_services_errors = clinic_services_errors
    },
}

const actions = {
    getClinicServices({commit}, params) {
        commit('setClinicServicesLoading', true)
        axios.get('/api/clinic/services', {params})
            .then(response => {
                commit('setClinicServices', response.data)
                commit('setClinicServicesLoading', false)
            })
            .catch(error => {
                console.log(error)
                commit('setClinicServicesLoading', false)
            })
    },
    getClinicService({commit}, id) {
        commit('setClinicServicesLoading', true)
        axios.get(`/api/clinic/services/${id}/show`, )
            .then(response => {
                commit('setClinicService', response.data?.data)
                commit('setClinicServicesLoading', false)
            })
            .catch(error => {
                console.log(error)
                commit('setClinicServicesLoading', false)
            })
    },
    async updateService({commit, dispatch}, data) {
        commit('setServicesErrors', [])
        commit('setServicesLoading', true)
        try {
            let response = await axios.put(`/api/clinic/services/${data.id}/update`, data)
            commit('setServices', response.data)
            dispatch('getServices')
            commit('setServicesLoading', false)
            return response.data
        } catch (e) {
            commit('setServicesErrors', e.response.data.errors)
            commit('setServicesLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}