import axios from 'axios'

const state = {
    drugs: {},
    drugs_loading: false,
    categories: [],
}

const getters = {
    drugs: state => state.drugs,
    categories: state => state.categories,

    drugs_loading: state => state.drugs_loading,
}

const mutations = {
    setDrugs(state, drugs) {
        state.drugs = drugs
    },
    setDrugsLoading(state, drugs_loading) {
        state.drugs_loading = drugs_loading
    },
    setCategories(state, categories) {
        state.categories = categories
    }
}

const actions = {
    getDrugs({commit}, params) {
        commit('setDrugsLoading', true)
        axios.get('/api/aptavik/drugs', {params})
            .then(response => {
                commit('setDrugs', response.data)
                commit('setDrugsLoading', false)
            })
    },
    getSavedDrugs({commit}, params) {
        commit('setDrugsLoading', true)
        axios.get('/api/aptavik/drugs/my', {params})
            .then(response => {
                commit('setDrugs', response.data)
                commit('setDrugsLoading', false)
            })
    },
    getSavedDrugsList({commit}, params) {
        commit('setDrugsLoading', true)
        axios.get('/api/aptavik/drugs/my/list', {params})
            .then(response => {
                commit('setDrugs', response.data)
                commit('setDrugsLoading', false)
            })
    },
    getCategories({commit}) {
        commit('setDrugsLoading', true)
        axios.get('/api/doctors/categories',)
            .then(response => {
                commit('setCategories', response.data.data)
                commit('setDrugsLoading', false)
            })
    },
    async saveDrugs({commit}, drugs) {
        commit('setDrugsLoading', true)
        try {
            await axios.post('/api/aptavik/drugs/send-request', {'drugs': drugs})
            commit('setDrugsLoading', false)
            return true
        }catch (e) {
            console.log(e)
            commit('setDrugsLoading', false)
            return false
        }
    },
    async  deleteDrug({commit}, id) {
        commit('setDrugsLoading', false)
        try {
            await axios.post('/api/doctors/drugs/' + id + '/delete')
            commit('setDrugsLoading', false)
        }catch (e) {
            console.log(e)
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}