<template>
  <component :is="resolveLayoutVariant">
<!--    <v-slide-x-transition mode="out-in">-->
      <!--        <transition name="transition" mode="out-in" appear>-->
    <router-view v-if="!(not_found || forbidden)"></router-view>
    <page-not-found v-else-if="not_found"></page-not-found>
    <page-forbidden v-else-if="forbidden"></page-forbidden>
      <!--        </transition>-->
<!--    </v-slide-x-transition>-->
  </component>
</template>

<script>
import LayoutBlank from '@/layouts/LayoutBlank';
import LayoutContent from '@/layouts/LayoutContent';
import LayoutRegister from '@/layouts/LayoutRegister';
import PageNotFound from "@/pages/Page/PageNotFound.vue";
import PageForbidden from "@/pages/Page/PageForbidden.vue";

export default {
  name: 'App',

  components: {
    PageForbidden,
    PageNotFound,
    LayoutBlank,
    LayoutRegister,
    LayoutContent,
    LayoutBase: () => import('@/layouts/LayoutBase'),
  },
  mounted() {
    let This = this
    window.axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response.status === 404) {
        This.not_found = true
      }
      if (error.response.status === 403) {
        This.forbidden = true
      }

      return Promise.reject(error)
    })
  },
  data() {
    return {
      not_found: false,
      forbidden: false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'ONLINEMED.uz';
        this.not_found = false
        this.forbidden = false
      }
    },
  },
  methods: {
  },
  computed: {
    resolveLayoutVariant() {
      let route = this.$route
      let token = this.$store.getters.token
      let user = this.$store.getters.seller
      if (route.meta.layout === 'base') return `layout-base`
      if (route.meta.layout === 'register') return `layout-register`
      if (route.meta.layout === 'content' || token && user && user.viewed && user.accepted) return `layout-content`
      if (route.meta.layout === 'blank' || !(token && user && user.viewed && user.accepted)) return 'layout-blank'

      return null
    }
  },
};
</script>
<style>

</style>