import Vue from 'vue'
import Vuex from 'vuex'
import clinic from './clinic'
import helpers from './helpers'
import drugs from './drugs'
import filter from './filter'
import layout from './layout'
import doctors from './doctors'
import service from './service'
import clinic_service from './clinic_service'


import createPersistedState from "vuex-persistedstate";
import SecureLS from 'secure-ls'

const ls = new SecureLS({isCompression: false});
Vue.use(Vuex)


export default new Vuex.Store({
    modules: {
        clinic_service,
        service,
        layout,
        filter,
        drugs,
        helpers,
        clinic,
        doctors
    },
    state: {
        title: 'Regions',
        storage: process.env.VUE_APP_STORAGE,
    },
    getters: {
        title: state => state.title,
        storage: state => state.storage
    },
    mutations: {
        setTitle(state, title) {
            state.title = title
        }
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })],
})


