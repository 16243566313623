import axios from 'axios'

const state = {
    doctors: [],
    users: [],
    users_errors: [],
    users_loading: false,
    doctors_loading: false,
    total_doctors: 0,
}

const getters = {
    doctors: state => state.doctors,
    users: state => state.users,
    doctors_loading: state => state.doctors_loading,
    users_loading: state => state.users_loading,
    users_errors: state => state.users_errors,
}

const mutations = {
    setDoctors(state, doctors) {
        state.doctors = doctors
    },
    setDoctorsLoading(state, doctors_loading) {
        state.doctors_loading = doctors_loading
    },
    setUsers(state, users) {
        state.users = users
    },
    setUsersLoading(state, users_loading) {
        state.users_loading = users_loading
    },
    setUsersErrors(state, users_errors) {
        state.users_errors = users_errors
    },
}

const actions = {
    getUsers({commit}, params) {
        commit('setUsersLoading', true)
        axios.get('/api/clinic/users', {params})
            .then(response => {
                commit('setUsers', response.data)
                commit('setUsersLoading', false)
            })
            .catch(error => {
                console.log(error)
                commit('setUsersLoading', false)
            })
    },
    getDoctors({commit}, params) {
        commit('setDoctorsLoading', true)
        axios.get('/api/clinic/doctors', {params})
            .then(response => {
                commit('setDoctors', response.data)
                commit('setDoctorsLoading', false)
            })
            .catch(error => {
                console.log(error)
                commit('setDoctorsLoading', false)
            })
    },
    async storeUser({commit, dispatch}, user) {
        commit('setUsersErrors', [])
        try {
            let response = await axios.post('/api/clinic/users/store', user)
            commit('setUsers', response.data)
            dispatch('getUsers')
            return response.data.ok
        } catch (e) {
            commit('setUsersErrors', e.response.data.errors)

            return false
        }

    },
}

export default {
    state,
    getters,
    mutations,
    actions
}